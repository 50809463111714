import React from 'react';
import './Unternehmen.scss';
import pfeil from '../../assets/Pfad.png';
import { CMS_BASE, getContent } from '../../cms';

export class Unternehmen extends React.Component {
    state = {
        cmsContent: null
    };

    componentDidMount = async () => {
        const content = await getContent("/company");
        this.setState({ cmsContent: content });
    }

    render() {
        return (
            <div>
                <section className="unternehmen-section">
                    <h2>{this.state?.cmsContent?.Title}</h2>
                    <div className="partner-container">
                        {this.state?.cmsContent?.Icons.map(icon => (
                            <img src={CMS_BASE + icon?.url} alt="" />
                        ))}
                    </div>
                    <div>
                        <p>{this.state?.cmsContent?.Text}</p>
                    </div>
                    <div className="pfeil">
                        <img src={pfeil} alt="" />
                    </div>
                </section>

                <section className="unternehmen-section">
                    <img src={CMS_BASE + this.state?.cmsContent?.JobBlock?.Icon.url} className="jobsImg" alt="" />
                    <h2>{this.state?.cmsContent?.JobBlock?.Title}</h2>
                    <p>
                        {this.state?.cmsContent?.JobBlock?.Body}
                        <a href="/jobs">{this.state?.cmsContent?.JobBlock?.LinkText}</a>
                    </p>
                </section>
            </div>
        )
    }

}

export default Unternehmen;