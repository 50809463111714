import React, {useState} from 'react';
import './Sidebar.scss';
import sidebarImg1 from '../../assets/sidebar_question.png';
import sidebarImg2 from '../../assets/sidebar_phone.png';

export const Sidebar = () => {
    const [isExpanded, setIsExpanded] = useState(false);
    //TODO Linter Warning: React Hook useCallback does nothing when called with only one argument. Did you forget to pass an array of dependencies?
    const toggle = React.useCallback(() => setIsExpanded(!isExpanded));
    return (
        <div className="sidebar">
            <a href="/anfrageformular">
                <img src={sidebarImg1} alt="" />
            </a>
            
                <img src={sidebarImg2} alt=""
                onClick={() => toggle()}
                />
            <div className="contact-details-container"
            style={{
                    display: isExpanded ? 'block' : 'none',
                    visibility: isExpanded ? '' : 'hidden'
                    }}
            >
                <p>
                Hotline: 030 33 60 70 49
                </p>
                <h3>Großraum Berlin:</h3>
                <p>
                    E-Mail: dispo-berlin@zac.gmbh
                </p>
                <h3>Großraum Bremen:</h3>
                <p>
                    E-Mail: dispo-bremen@zac.gmbh
                </p>
                <h3>Großraum Niedersachsen:</h3>
                <p>
                    E-Mail: dispo-niedersachsen@zac.gmbh
                </p>
            </div>
            
        </div>
    )
}

export default Sidebar
