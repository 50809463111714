/* import React from "react";
import "./Jobs.scss";
import { getContent } from "../../cms";
import JobCard from "./JobCard";

export class Jobs extends React.Component {
  state = {
    cmsContent: null,
  };
  
  async componentDidMount() {
    const content = await getContent("/jobs");
    this.setState({ cmsContent: content });
    
  }
  
  render() {    
    return (
      <>
        <h2>Jobs</h2>
      
        <div className="job-postings-container">
          {this.state.cmsContent && this.state.cmsContent.map((job) => (
            <div key={job.id}>
              <JobCard jobData={job} />
            </div>
          ))}
        </div>
      </>
    );
  }
}

export default Jobs;
 */

import React from "react";
import "./Jobs.scss";
import { getContent } from "../../cms";
import JobCard from "./JobCard";

export class Jobs extends React.Component {
  state = {
    cmsContent: null,
  };
  
  async componentDidMount() {
    const content = await getContent("/jobs");
    
    // Sort the jobs by the published_at date in descending order
    const sortedContent = content.sort((a, b) => new Date(b.published_at) - new Date(a.published_at));
    
    this.setState({ cmsContent: sortedContent });
  }
  
  render() {    
    return (
      <>
        <h2>Jobs</h2>
      
        <div className="job-postings-container">
          {this.state.cmsContent && this.state.cmsContent.map((job) => (
            <div key={job.id}>
              <JobCard jobData={job} />
            </div>
          ))}
        </div>
      </>
    );
  }
}

export default Jobs;