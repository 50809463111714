import React from 'react';
import './Law.scss';

export const Compliance = () => {
    return (
        <div className="law-container">
                            <h1>Compliance</h1>

            <h3>E-Mail: compliance@zac.gmbh</h3>
            <h3>Rufnummer: 017635744023</h3>
                
        </div>
    )
}

export default Compliance
