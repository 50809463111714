import React from 'react';
import './Login.scss';

 export const Login = () => {
    return (
        <form 
        method="POST" 
        action="https://intern.zacgmbh.de/index.php?option=com_login_box&amp;task=login" 
        className="login-container" 
        >

        <div className="login-inner-container">
            <h1>Einloggen</h1>
                <div className="username input-container">
                    <label for="username">Benutzername</label>
                    <input type="text" name="username" />
                </div>
                <div className="password input-container">
                    <label for="password">Passwort</label>
                    <input name="passwd" type="password" />
                </div>
                <div className="login-btns">
                    <input type="submit" class="send" />
                </div>
        </div>
    </form>
    )
}

export default Login
