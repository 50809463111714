import axios from "axios";

//                     2min
// const MAX_CACHE_TIME = 2 * 60 * 1000;
export const CMS_BASE =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_ZAC_CMS_URL_PROD
    : process.env.REACT_APP_ZAC_CMS_URL_DEV;

//function getCache() {
//  const cacheJSON = localStorage.getItem("cmsCache");
//  return cacheJSON ? JSON.parse(cacheJSON) : {};
//}

//function writeCache(path, cacheElement) {
//  //ReReading the cache for thread safety
//  const cache = getCache();
//  cache[path] = cacheElement;
//  localStorage.setItem("cmsCache", JSON.stringify(cache));
//}

export async function getContent(path) {
  // let cache = getCache()[path];
  // if (
  //   !cache ||
  //   (cache && Date.now() - cache._cacheTimestamp > MAX_CACHE_TIME)
  // ) {
  //   try {
  //     cache = (await axios.get(CMS_BASE + path)).data;
  //     cache._cacheTimestamp = Date.now();
  //     writeCache(path, cache);
  //     return cache;
  //   } catch (err) {
  //     alert("Fehler: Verbindung zum CMS fehlgeschlagen");
  //     throw new Error(`Could not GET ${path} ${err}`);
  //   }
  // }
  // return cache;
  try {
    return (await axios.get(CMS_BASE + path)).data;
  } catch (err) {
    alert("Fehler: Verbindung zum CMS fehlgeschlagen");
    throw new Error(`Could not GET ${path} ${err}`);
  }
}

export function title2href(title) {
  return encodeURIComponent(title ?? "");
}

/*
export function href2title(href){
    return href.slice(1).replaceAll("-", " ");
}*/
