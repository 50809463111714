import React from 'react'
import "./DankeSeite.scss";

export const DankeSeite = () => {
    return (
        <div className="thankYou">
            <div>
                <h2>Vielen Dank für Ihre Anfrage</h2>
                <h4><a href="/">Zurück zur Startseite</a></h4>
            </div>
        </div>
    )
}

export default DankeSeite
