import React from 'react';
import './UnserService.scss';
import { IconBox } from '../IconBox/IconBox';
import pfeil from '../../assets/section_arrow.png';
import { CMS_BASE, getContent } from '../../cms';
export class UnserService extends React.Component {
    state = {
        cmsContent: null
    };

    componentDidMount = async () => {
        const content = await getContent("/service");
        this.setState({ cmsContent: content });
    }

    render() {
        return (
            <div>
                <section className="service-section">
                    <h2>{this.state?.cmsContent?.Title}</h2>
                    <div className="icon-container">
                        {this.state?.cmsContent?.Icons.map(icon => (
                            <a href={"/service/" + icon.Link}>
                                <IconBox className="icon-box" img={CMS_BASE + icon?.Image?.url} text={icon.Text} alt="" />
                            </a>
                        ))}
                    </div>
                    <div className="pfeil">
                        <img src={pfeil} alt="" />
                    </div>
                </section>

                <section className="service-section">
                    <h2>{this.state?.cmsContent?.TextBlock?.Title}</h2>
                    <p>{this.state?.cmsContent?.TextBlock?.Body}</p>
                    <div className="icon-container">
                        {this.state?.cmsContent?.MoreIcons.map(icon => (
                            <a href={"/service/" + icon.Link}>
                                <IconBox className="icon-box" img={CMS_BASE + icon?.Image?.url} text={icon.Text} />
                            </a>
                        ))}
                    </div>
                </section>
            </div>
        )
    }
}

export default UnserService;