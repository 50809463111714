import React from 'react';
import './IconBox.scss';

export function IconBox(props) {
    return (
            <div className="iconbox">
            <img src={props.img} alt="" />
            <span>
                {props.text}
            </span>
        </div>
        
    )
} 
