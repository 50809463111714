import React from 'react';
import './Footer.scss'; 

export const Footer = () => {
    return (
        <div>
            <footer>
                <ul>
                    <a href="/impressum"><li>Impressum</li></a>
                    <a href="/datenschutz"><li>Datenschutz</li></a>
                    <a href="/compliance"><li>Compliance</li></a>
                </ul>
            </footer>
        </div>
    )
}

export default Footer
