import './App.scss';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import { Startpage } from './components/Startpage/Startpage.jsx';

import { Header } from './components/Header/Header.jsx';
import { Sidebar } from './components/Sidebar/Sidebar.jsx';
import { Footer } from './components/Footer/Footer.jsx';
import { UnserService } from './components/UnserService/UnserService';
import { Unternehmen } from './components/Unternehmen/Unternehmen';
import { DankeSeite } from './components/FormTesting/DankeSeite/DankeSeite';
import { Login } from './components/Login/Login';
import { Impressum } from './components/Law/Impressum';
import { Datenschutz } from './components/Law/Datenschutz';
import { FormTest } from './components/FormTesting/Form';
import { ServicePage } from './components/UnserService/ServicePage/ServicePage';
import { Jobs } from './components/Jobs/Jobs';
import { NewStartPageOne } from './components/NewStartPage/NewStartPageOne.jsx';
import { NewStartPageTwo} from './components/NewStartPage/NewStartPageTwo.jsx';
import { NewStartPageThree } from './components/NewStartPage/NewStartPageThree.jsx';
import { Compliance } from './components/Law/Compliance.jsx';

function App() {
  return (
    <div className="app-container">
      <Header />
      <Router>
        <Switch>
          {/* <Route path="/" exact>
            <Startpage />
          </Route> */}
          <Route path="/" exact>
            <NewStartPageOne />
          </Route>
          <Route path="/newstartpage2" exact>
            <NewStartPageTwo />
          </Route>
          <Route path="/newstartpage3" exact>
            <NewStartPageThree />
          </Route>
          
          <Route path="/unser-service" exact>
            <UnserService />
          </Route>
          <Route path="/unternehmen" exact>
            <Unternehmen />
          </Route>
          <Route path="/anfrageformular">
            <FormTest />
          </Route>
          <Route path="/jobs" exact>
            <Jobs />
          </Route>
          <Route path="/anfrageformular-test">
            <FormTest />
          </Route>
          <Route path="/danke" exact>
            <DankeSeite />
          </Route>
          <Route path="/impressum" exact>
            <Impressum />
          </Route>
          <Route path="/datenschutz" exact>
            <Datenschutz />
          </Route>
          <Route path="/login" exact>
            <Login />
          </Route>
          <Route>
            <Compliance />
          </Route>
          <Route path="/service/:subPage" component={ServicePage} />
        </Switch>
      </Router>
      <Sidebar />
      <Footer />
    </div>
  );
}

export default App;
