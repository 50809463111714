import React from 'react';
import { getContent, CMS_BASE, title2href } from '../../cms'
import sectionArrow from '../../assets/section_arrow.png';

export class NewStartPageThree extends React.Component {
  state = {
    cmsBlocks: null
  };

  componentDidMount = async () => {
    const content = await getContent("/home");
    this.setState({ cmsBlocks: [content.ContentBlocks[2]] });
  }

  render() {
    return (
      <>
        <div className="flex">
          <main>
            {this.state?.cmsBlocks?.map((block, i) => (
              <React.Fragment key={block.Title}>
                <section className="start-section" id={title2href(block.Title)}>
                  <h1>{block.Title}</h1>
                  <video src={CMS_BASE + block?.Video?.url} autoPlay playsInline className="grafik" muted
                  style={{width: '100%'}}
                  ></video>
                  <p className="alpha">
                    {block.Text}
                  </p>
                </section>
                {i !== parseInt(this.state.cmsBlocks.id) - 1 &&
                  <a href={"/"}>
                    <img src={sectionArrow} className="section-arrow" alt="" />
                  </a>
                }
              </React.Fragment>
            ))}
          </main>
        </div>
      </>
    )
  }
}

export default NewStartPageThree;