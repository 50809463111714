import React, { useState } from 'react';
import './Form.scss';

export function FormTest() {

    const [selectDisabled, setSelectDisabled] = useState(false);
    const [fileInputVisible, setFileInputVisible] = useState(false);
    const [anliegenVertragspartner, setAnliegenVertragspartner] = useState("");
    const [privacyCheck, setPrivacyCheck] = useState(false);

    const togglePrivacy = () => setPrivacyCheck(!privacyCheck);
    const selectFunction = (e) => setAnliegenVertragspartner(e.target.value);

    const disable = () => {
        setTimeout(() => {
            setSelectDisabled(true)
        }, 1);
    };
    disable();

    return (
        <>
            <h2>Digitales Anfrageformular</h2>
            <form
                method="POST"
                action={(process.env.NODE_ENV === "production" ? process.env.REACT_APP_ZAC_BACKEND_URL_PROD : process.env.REACT_APP_ZAC_BACKEND_URL_DEV) + "/forms/inquiry"}
                enctype="multipart/form-data"
            >
                <div className="form-container">
                    <div>
                        <div className="select-wrapper">
                            <select name="zipArea" className="select-input" required>
                                <option value="" disabled={selectDisabled} className="option-input">Postleitzahlgebiet</option>
                                <option value="1" className="option-input">1xxxxx</option>
                                <option value="2" className="option-input">2xxxxx</option>
                                <option value="3" className="option-input">3xxxxx</option>
                            </select>
                        </div>
                    </div>
                    <div>
                        <div className="select-wrapper">
                            <select name="issue" className="select-input" onChange={selectFunction} required>
                                <option value="" disabled={selectDisabled} className="option-input">Anliegen</option>
                                <option value="Kundenanliegen" className="option-input">Kundenanliegen</option>
                                <option value="Anliegen Vertragspartner" className="option-input">Anliegen Vertragspartner</option>
                                <option value="Sonstige" className="option-input">Sonstige</option>
                            </select>
                        </div>
                    </div>
                    
                </div>
                <div className="form-container">
                <div>
                        <div className="select-wrapper">
                            <select name="subject" className="select-input" required>
                                <option value="" disabled={selectDisabled} className="option-input">Betreff</option>
                                <option value="Liefertermin" className="option-input">Liefertermin</option>
                                <option value="Reklamation" className="option-input">Reklamation</option>
                                <option value="Kooperationsanfrage" className="option-input">Kooperationsanfrage</option>
                                <option value="Sonstige" className="option-input">Sonstige</option>
                            </select>
                        </div>
                    </div>
                    <div>
                        <div className="select-wrapper"
                        style={{
                            display: anliegenVertragspartner === "Anliegen Vertragspartner" ? 'block' : 'none'
                        }}
                        >
                            <select name="priority" id="" className="select-input">
                            <option value="" disabled={selectDisabled} className="option-input">Priorität</option>
                                <option value="1" className="option-input">Niedrig (1d Bearbeitungszeit)</option>
                                <option value="2" className="option-input">Mittel (90min Bearbeitungszeit)</option>
                                <option value="3" className="option-input">Hoch (30min Bearbeitungszeit)</option>
                            </select>
                        </div>
                        <div>
                            
                        </div>
                    </div>
                </div>


                <div className="form-container">
                    <div className="text-input">
                        <label>Name und Belegnummer </label>
                        <input type="text" name="nameAndBillNo" placeholder="50 Zeichen" required />
                    </div>
                    <div className="text-input">
                        <label>Rufnummer oder Mail-Adresse </label>
                        <input type="text" name="phoneOrMail" placeholder="50 Zeichen" required />


                    </div>

                
            </div>
            <div className="text-input">
                    <label>Nachricht </label>
                    <textarea name="message" cols="30" rows="10" 
                    placeholder={"Bitte nennen Sie alle relevanten Informationen, die unseren Sachbearbeitern dabei helfen, Ihr Anliegen bestmöglich lösen zu können. \n\n" +
                    (anliegenVertragspartner === "Anliegen Vertragspartner" ? "Obligatorisch sind Ihr Markt, Ihr Standort, Ihr Name und Ihre Mailadresse." : "") }
                    ></textarea>
                </div>
                <div>
                    <label className="file-input-label" onClick={() => setFileInputVisible(true)}> + Dokument hochladen <br /><br />
                        <span style={{ display: fileInputVisible ? 'block' : 'none' }}>
                            <input type="file" name="file" accept="image/png, image/jpeg" />
                        </span>
                    </label>
                </div>

                <div className="privacy"
                onClick={togglePrivacy}
                >
                    <div className="checkbox"
                    style={{
                        backgroundColor: privacyCheck ? '#03036A' : 'white'
                    }}
                    ></div>
                    <p>Ich stimme der <a href="/datenschutz">Datenschutzerklärung</a> zu</p>
                </div>
                <div className="submit">
                    <button type="submit"
                    disabled={!privacyCheck}
                    style={{
                        borderColor: privacyCheck ? '' : 'grey',
                        color: privacyCheck ? '' : 'grey'
                    }}
                    >Senden</button>

                </div>
            </form>
            
        </>
    )
}

export default FormTest;