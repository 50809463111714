import React, { useState } from "react";
import TimeImg from "../../assets/time.png";
import Place from "../../assets/home.png";
import Map from "../../assets/map-point.png"
import Pfeil from '../../assets/Pfad.png';
import { CMS_BASE } from "../../cms";

export default function JobCard(props) {
  const [isExpanded, setIsExpanded] = useState(false);
  const toggleExpandable = React.useCallback(() => setIsExpanded(!isExpanded), [isExpanded]);


  const structuredData = {
    "@context": "https://schema.org/",
    "@type": "JobPosting",
    title: props.jobData.JobOfferBlock.Title,
    description: props.jobData.JobOfferBlock.JobDescription,
    datePosted: props.jobData.published_at,
    validThrough: props.jobData.JobOfferBlock.ValidTill,
    employmentType:
      props.jobData.JobOfferBlock.EmploymentType === "Vollzeit"
        ? "FULL_TIME"
        : "PART_TIME",
    hiringOrganization: {
      "@type": "Organization",
      name: "ZAC Service und Dienstleistungs GmbH",
      sameAs: "https://zacgmbh.de/",
    },
    directApply: true,
    jobLocation: {
      "@type": "Place",
      address: {
        "@type": "PostalAddress",
        streetAddress: props.jobData.JobOfferBlock.StreetAndNumber,
        addressLocality: props.jobData.JobOfferBlock.City,
        addressRegion: props.jobData.JobOfferBlock.City,
        postalCode: props.jobData.JobOfferBlock.Plz,
        addressCountry: "DE",
      },
    },
  };
  return (
    <div className="job-posting">
      
      <div className="img-container"
      style={{background: "url(" + CMS_BASE + props.jobData.JobOfferBlock.Image.url + ")", backgroundSize: "cover"}}
      ></div>
      <div className="info-container">
        <div className="headings">
          <h3>{props.jobData.JobOfferBlock.Title}</h3>
          <p>{props.jobData.JobOfferBlock.UnderHeadline}</p>
        </div>
        <div className="description">
          <div className="icon-description">
            <img
              src={Place}
              alt=""
            />
            <p> {props.jobData.JobOfferBlock.StreetAndNumber} {props.jobData.JobOfferBlock.City}</p>
          </div>
          <div className="icon-description">
            <img
              src={Map}
              alt=""
            />
            <p> Einsatzgebiet: {props.jobData.JobOfferBlock.Region}</p>
          </div>
          <div className="icon-description">
            <img src={TimeImg} alt="type" />
            <p> {props.jobData.JobOfferBlock.EmploymentType}</p>
          </div>
        </div>
      </div>
      <div className={isExpanded ? "apply-info" : "invisible"}>
      <h3>Über uns</h3>
        <p>
          {props.jobData.JobOfferBlock.AboutUs ? props.jobData.JobOfferBlock.AboutUs.split("\n").map((line) => (
            <span id={line.id}
            className="break-element"
            >
              {line}
            </span>
          )) : ""}
        </p>
        <h3>Ihr Aufgabenbereich</h3>
        <p>{props.jobData.JobOfferBlock.JobDescription.split("\n").map((line) => (
          <span id={line.id}
          className="break-element"
          >
            {line}
          </span>
        ))} </p>
        <h3>Unser Angebot</h3>
        <p>{props.jobData.JobOfferBlock.OfferingDescription.split("\n").map((line) => (
          <span id={line.id}
          className="break-element"
          >
            {line}
          </span>
        ))}</p>
        <h3>Ihre Qualifikationen</h3>
        <p>{props.jobData.JobOfferBlock.Requirements.split("\n").map((line) => (
          <span id={line.id}
          className="break-element"
          >
            {line}
          </span>
        ))}</p>
        <h3>Kontaktieren Sie uns</h3>
        <p>
          {props.jobData.JobOfferBlock.ContactUs ? props.jobData.JobOfferBlock.ContactUs.split("\n").map((line) => (
            <span id={line.id}
            className="break-element"
            >
              {line}
            </span>
          )) : ""}
        </p>
        <div className="apply">
          <a
            href={`mailto:${props.jobData.JobOfferBlock.Email == "" ? "bewerbung@zac.gmbh" : props.jobData.JobOfferBlock.Email }?subject=${props.jobData.JobOfferBlock.Title}`}
          >
            <button className="apply-btn">Jetzt bewerben</button>
          </a>
        </div>
      </div>
      <div className="expand-btn" onClick={() => toggleExpandable()}>
        <img src={Pfeil} alt="" />
      </div>
      <script type="application/ld+json">
        {JSON.stringify(structuredData)}
      </script>
    </div>
  );
}

