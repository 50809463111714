import React from 'react';
import { withRouter } from 'react-router-dom';
import './ServicePage.scss';
import { CMS_BASE, getContent } from '../../../cms';
export class ServicePage extends React.Component {
    state = {
        cmsContent: null
    };
   
    async componentDidMount(){
        const content = await getContent("/service-sub-pages?Link=" + this.props.match.params.subPage);
        this.setState({ cmsContent: content?.[0] });
    } 

    render() {
        return (
            <div className="serive-page-container">
                <h1>
                    {this.state?.cmsContent?.Title}
                </h1>
                <div className="service-page-content-container">
                    <img src={CMS_BASE + this.state?.cmsContent?.Image?.url} alt="" />
                    <p>
                        {this.state?.cmsContent?.Text}
                    </p>
                </div>
            </div>
        )
    }
}

export default withRouter(ServicePage);
